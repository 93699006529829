import './scss/app.scss';
import tabs from './js/tabs';
import Pristine from 'pristinejs';
import Inputmask from 'inputmask';
import axios from './js/axiosWithSpinner';
import Choices from 'choices.js';
import { getOptionsFromResponse, initTwoLengthValidatorAndMask } from './functions';
import { successModal, modal, errorModal, errorSessionModal } from './js/modals';

const choicesOptions = {
    itemSelectText: '',
    noResultsText: 'Нет результатов',
    noChoicesText: 'Нет результатов',

    searchResultLimit: 10,
    fuseOptions: {
        threshold: 0,
        ignoreLocation: true,
    },
    searchFields: ['label']
};

var login_reg = new tabs('login_tabs');
var regfisorg = new tabs('application_tabs');

debugger;
const registrationFisForm = document.getElementById('registration_fis_form');
const registrationOrgForm = document.getElementById('registration_org_form');
const formFisBtn = registrationFisForm.querySelector('button[type="submit"]');
const formOrgBtn = registrationOrgForm.querySelector('button[type="submit"]');
const pristineFis = new Pristine(registrationFisForm);
const pristineOrg = new Pristine(registrationOrgForm);

registrationFisForm.querySelectorAll('[data-mask-pattern]').forEach(f => {
    f._imask_object = Inputmask({
        regex: f.getAttribute('data-mask-pattern')
    }).mask(f);
});

registrationFisForm.querySelectorAll('[data-mask]').forEach(f => {
    f._imask_object = Inputmask({
        mask: f.getAttribute('data-mask')
    }).mask(f);
});

registrationOrgForm.querySelectorAll('[data-mask-pattern]').forEach(f => {
    f._imask_object = Inputmask({
        regex: f.getAttribute('data-mask-pattern')
    }).mask(f);
});

registrationOrgForm.querySelectorAll('[data-mask]').forEach(f => {
    f._imask_object = Inputmask({
        mask: f.getAttribute('data-mask')
    }).mask(f);
});

initTwoLengthValidatorAndMask(registrationFisForm, pristineFis, Inputmask);
initTwoLengthValidatorAndMask(registrationOrgForm, pristineOrg, Inputmask);

formFisBtn.setAttribute('disabled', 'true');
formOrgBtn.setAttribute('disabled', 'true');

['change', 'input'].map(event => {
    registrationFisForm.addEventListener(event, e => {
        console.log('on ' + event);
        const isFormValid = pristineFis.validate({}, true);
        if (isFormValid) {
            formFisBtn.removeAttribute('disabled');
        } else {
            formFisBtn.setAttribute('disabled', true);
        }
    });
    registrationOrgForm.addEventListener(event, e => {
        console.log('on ' + event);
        const isFormValid = pristineOrg.validate({}, true);
        if (isFormValid) {
            formOrgBtn.removeAttribute('disabled');
        } else {
            formOrgBtn.setAttribute('disabled', true);
        }
    });
});

console.log('index.js');

document.getElementById('registration_fis_form')?.addEventListener('submit', function (e) {
    submitRegistrationFrom(e);
});

document.getElementById('registration_org_form')?.addEventListener('submit', function (e) {
    submitRegistrationFrom(e);
});

function submitRegistrationFrom(e) {
    e.preventDefault();

    document.querySelectorAll('.pristine-error').forEach(function (el) {
        el.innerHTML = '';
    })

    const bodyFormData = new FormData(e.currentTarget);
    var form = e.currentTarget;

    axios({
        method: "post",
        url: "/Login/CreateUser",
        data: bodyFormData,
    })
        .then(function (response) {
            if (response.data.success == true)
                successModal(response.data.param, { preConfirmCallback: () => { window.location.href = '/Login/Signin' } });
            else {
                var errors = {};
                for (var i = 0; i < response.data.param.length; i++) {
                    errors[response.data.param[i]['Key']] = response.data.param[i]['Value'][0];
                }

                showErrors(form, errors);
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            errorSessionModal();
        });
}

function showErrors(form, errors) {
    for (var key in errors) {
        if (errors.hasOwnProperty(key)) {
            var input = form.querySelector('input[name="' + key + '"]');
                //document.querySelector('#registration_form input[name="' + key + '"]');
            input.previousElementSibling.innerHTML = errors[key];
            input.previousElementSibling.removeAttribute('style')
        }
    }
}

//document.getElementById('view_agreement').addEventListener('click', function (e) {
//    e.stopPropagation();
//    modal(
//        document.getElementById('user_agreement_template').innerHTML,
//        {
//            width: 850,
//            confirmButtonText: 'Закрыть',
//        }
//    )
//});

//document.getElementById('view_agreement1').addEventListener('click', function (e) {
//    e.stopPropagation();
//    modal(
//        document.getElementById('user_agreement_template').innerHTML,
//        {
//            width: 850,
//            confirmButtonText: 'Закрыть',
//        }
//    )
//});


window.CreateTrialTest = function () {
    axios.get(`/TrialTestRun/Create`)
        .then(function (response) {
            let validator;
            modal(response.data, {
                title: 'Пробный экзамен',
                showCancelButton: true,
                width: 715,
                heightAuto: true,
                padding: 30,
                confirmButtonText: 'Приступить к тестированию',
                cancelButtonText: 'Закрыть',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-danger btn-type-2',
                    title: 'modal-title',
                    cancelButton: 'btn btn-default btn-type-2',
                    container: 'modal-content',
                },
                didOpenCallback: (swalRef) => {
                    const form = swalRef.getHtmlContainer().querySelector('.pristine-validate');
                    validator = new Pristine(form);

                    const profStandardModalDropdown = new Choices(document.getElementById('profstandardModal'),
                        {
                            ...choicesOptions
                        }
                    );

                    axios.get('/DictionaryProfessionalStandart/SelectStandartList')
                        .then(function (response) {
                            const options = getOptionsFromResponse(response);
                            profStandardModalDropdown.clearChoices();
                            profStandardModalDropdown.setChoices([profStandardModalDropdown.config.choices.find(choice => choice.placeholder)]);
                            profStandardModalDropdown.setChoices(options);
                        })
                        .catch(function (error) {
                            console.error(error);
                            errorSessionModal();
                        });

                    const qualificationModalDropdown = new Choices(document.getElementById('qualificationModal'),
                        {
                            ...choicesOptions
                        }
                    );

                    document.getElementById('profstandardModal').addEventListener('change', function (e) {
                        const profStandardId = e.detail.value;
                        document.getElementById('testInfo').innerText = '';
                        axios.get('/DictionaryProfessionalQualification/SelectQualificationList', {
                            params: {
                                standartId: profStandardId
                            }
                        }).then(function (response) {
                            qualificationModalDropdown.clearChoices();
                            qualificationModalDropdown.setChoices([qualificationModalDropdown.config.choices.find(choice => choice.placeholder)]);
                            qualificationModalDropdown.setChoices(getOptionsFromResponse(response));
                        }).catch(function (error) {
                            console.error(error);
                            errorSessionModal();
                        });
                    });

                    document.getElementById('qualificationModal').addEventListener('change', function (e) {
                        axios.get('/TrialTestRun/SelectTest', {
                            params: {
                                qualificationId: e.currentTarget.value,
                                typeTest: 0
                            }
                        }).then(function (response) {
                            if (response.data.param == null) {
                                document.getElementById('testInfo').innerText = 'Для данной квалификации не заведен пробный экзамен';
                                return;
                            }

                            var minutes = parseInt(response.data.param.TimeForTestInSeconds);
                            if (response.data.param.questionCount == 1)
                                document.getElementById('testInfo').innerText = 'В данной квалификации 1 вопрос, время прохождения экзамена ' + minutes + ' минут';
                            else {
                                if (response.data.param.questionCount > 0 && response.data.param.questionCount <= 4)
                                    document.getElementById('testInfo').innerText = 'В данной квалификации ' + response.data.param.questionCount + ' вопроса, время прохождения экзамена ' + minutes + ' минут';
                                else
                                    document.getElementById('testInfo').innerText = 'В данной квалификации ' + response.data.param.questionCount + ' вопросов, время прохождения экзамена ' + minutes + ' минут';
                            }

                        }).catch(function (error) {
                            console.error(error);
                            errorSessionModal();
                        });
                    });
                },
                preConfirmCallback: (swalRef) => {
                    if (!validator.validate(
                        swalRef.getHtmlContainer().querySelector('.pristine-validate')
                            .querySelectorAll('.form-group:not(.d-none) .js-choices')
                    )) {
                        return false;
                    }

                    //const form = swalRef.getHtmlContainer().querySelector('.pristine-validate');

                    //const bodyFormData = new FormData(form);

                    axios({
                        method: "get",
                        url: `/TrialTestRun/OpenTest?qualificationId=${document.getElementById('qualificationModal').value}`,

                    })
                        .then(function (response) {
                            if (response.data.success == true) {
                                //successModal('Запись сохранена', { preConfirmCallback: () => window.location = `/TrialTestRun/Question` });
                                window.location = `/TrialTestRun/Question`;
                            }
                            else {
                                swalRef.showValidationMessage(`Ошибка: ${response.data.error}`);
                            }
                        })
                        .catch(function (response) {
                            //handle error
                            console.log(response);
                            errorSessionModal();
                        });

                    return false;
                },
                thenCallback: () => {

                }
            })
        })
        .catch(function (error) {
            console.error(error);
            errorSessionModal();
        });
}

Array.from(document.querySelectorAll('input[name="Email"]')).forEach(input => {
    input.onkeyup = function (e) {
        e.target.value = e.target.value.toLowerCase();        
    }
});


Array.from(document.querySelectorAll('input[name="LastName"]')).forEach(input => {
    input.onkeyup = function (e) {
        //e.target.value = e.target.value.trimStart();
        e.target.value = getValues(e.target.value);
    }
});

Array.from(document.querySelectorAll('input[name="FirstName"]')).forEach(input => {
    input.onkeyup = function (e) {
      //  e.target.value = e.target.value.trimStart();
        e.target.value = getValues(e.target.value);
    }
});


Array.from(document.querySelectorAll('input[name="MiddleName"]')).forEach(input => {
    input.onkeyup = function (e) {
        //e.target.value = e.target.value.trimStart();
        e.target.value = getValues(e.target.value);
    }
});

function getValues(filedS) {
    const enteredChar = filedS.trimStart();
    var result = '';
    var sp = '';
    for (let i = 0; i < enteredChar.length; i++) {
        if (i == 0) {
            if (!/^[А-ЯЁ]*$/.test(enteredChar[i])) {
                result = result + enteredChar[i].toUpperCase();
            }
            else {
                result = result + enteredChar[i];
            }
        }
        else {
            if (sp == '-' || sp == ' ') {
                if (!/^[А-ЯЁ]*$/.test(enteredChar[i])) {
                    result = result + enteredChar[i].toUpperCase();
                }
                else {
                    result = result + enteredChar[i];
                }
            }
            else {
                result = result + enteredChar[i].toLowerCase();
            }
        }
        sp = enteredChar[i];
    };

    return result;
}

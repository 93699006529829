﻿import axios from 'axios';
import { showSpinner, hideSpinner } from '../functions';

const axiosInstance = axios.create();
const MIN_SHOW_TIME_MS = 500;

axiosInstance.interceptors.request.use(function (config) {
    showSpinner();
    return config;
}, function (error) {
    setTimeout(() => {
        hideSpinner();
    }, MIN_SHOW_TIME_MS);
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (config) {
    setTimeout(() => {
        hideSpinner();
    }, MIN_SHOW_TIME_MS);

    if (config.data != null && typeof config.data == 'string' && config.data.includes('<!DOCTYPE html>'))
        throw new Error('doc in modal');

    return config;
}, function (error) {
    setTimeout(() => {
        hideSpinner();
    }, MIN_SHOW_TIME_MS);
    return Promise.reject(error);
});

export default axiosInstance;
export default function tabs(tabId) {
    const tabsContainer = document.querySelector(`#${tabId}`);
    const hash = window.location.hash;
    const switchTabs = target => {
        if (target.classList.contains('active')) {
            return false;
        }
        [...tabsContainer.querySelectorAll(':scope > .tab-item')]
            .map(node => node.classList.remove('active'));

        target.closest('.tab-item').classList.add('active');

        [...document.querySelectorAll(`[tabs=${tabId}]`)]
            .map(t => t.classList.remove('active'));

        const hash = target.getAttribute('href');
        document.querySelector(hash).classList.add('active');
        if(!target.classList.contains('notpush'))
            history.pushState(null, '', hash);
    };

    const checkHash = hash => {
        const hashTab = document.querySelector(`[href="${hash}"]`);
        return hashTab;
    }


    const hashTab = checkHash(hash);
    if (hashTab) {
        switchTabs(hashTab);
    }

    window.addEventListener('hashchange', function () {
        const hash = window.location.hash;
        const tab = checkHash(hash);
        if (tab) {
            switchTabs(tab);
        }
    }, false);

    tabsContainer.addEventListener('click', function (e) {
        const target = e.target;
        if (!target.classList.contains('tab-item-link')) {
            return false;
        }
        e.preventDefault();
        switchTabs(target);
    });

    this.goToTab = function(hash) {
        const hashTab = checkHash(hash);
        if (hashTab) {
            switchTabs(hashTab);
        }
    }

    return this;
}